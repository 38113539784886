import * as focusTrap from 'focus-trap'

class HdmSearch {

  constructor () {
    this.searchModalTrigger = document.querySelector('#search-modal-trigger')
    this.searchOverlay = document.querySelector('#search-overlay')
    this.searchModal = this.searchOverlay.querySelector('#search-modal')
    this.searchModalCancel = this.searchModal.querySelector('#search-modal__cancel')
    this.searchAgainTrigger = document.querySelector('.search-again-trigger')
    this.bodyElement = document.querySelector('body')

    if (this.searchModalTrigger && this.searchOverlay && this.searchModal) {
      this.searchModalTrigger.addEventListener('click', (e) => {
        e.preventDefault()

        if(this.searchOverlay.classList.contains('active')) {
          this.closeSearchOverlay()
        } else {
          this.openSearchOverlay()
        }

      })

      this.searchModalCancel.addEventListener('click', (e) => {
        e.preventDefault()
        this.closeSearchOverlay()
      })

      if (!this.searchOverlay.classList.contains('active') && this.searchAgainTrigger) {
        this.searchAgainTrigger.addEventListener('click', (e) => {
          e.preventDefault()
          this.openSearchOverlay()
        })
      }

    }
  }

  openSearchOverlay () {
    this.searchOverlay.classList.add('active')
    this.searchModal.ariaHidden = 'false'
    this.bodyElement.classList.toggle('is-overflow-hidden')

    window.scrollTo(0,0)

    this.searchFocusTrap = focusTrap.createFocusTrap(this.searchModal, { escapeDeactivates: false, allowOutsideClick: true })
    this.searchFocusTrap.activate()

    this.escapeListener = this.handleEscapeKey.bind(this)

    document.addEventListener('keydown', this.escapeListener)
  }

  closeSearchOverlay () {
    this.searchOverlay.classList.remove('active')
    this.searchModal.ariaHidden = 'true'
    this.bodyElement.classList.toggle('is-overflow-hidden')

    this.searchFocusTrap.deactivate()

    document.removeEventListener('keydown', this.escapeListener)
  }

  handleEscapeKey(e) {
    if(e.keyCode === 27) {
      this.closeSearchOverlay()
    }
  }

}

window.addEventListener('DOMContentLoaded', () => {
  window.hdmSearch = new HdmSearch()
})